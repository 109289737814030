





























































import { Component, Inject, Mixins } from 'vue-property-decorator'
import { SizeMap } from '../../../dsl/composables'

import { AllowedAddon, ContentSneakPeakData, ImageData } from '../../../contexts'
import { logger, PARENT_CONTENT_DATA_KEY } from '../../../support'
import { toImageProps } from '../../../front/shared/support'
import { StructureConfigurable } from '../../../support/mixins'
import { IShareService, ShareServiceType, ShareType } from '../../../front/shared/contracts/share'

import { AbstractModuleUi } from '../../abstract/ui'
import { ArticleHeaderModule } from '../ArticleHeader.contracts'
import { AnyObject } from '@movecloser/front-core'
import { ImageProps } from '../../../dsl/atoms/Image'
import {
  ARTICLE_HEADER_COMPONENT_KEY,
  ARTICLE_HEADER_DEFAULT_CONFIG
} from '../ArticleHeader.config'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
@Component<ArticleHeaderModuleUi>({
  name: 'ArticleHeaderModuleUi',
  components: {},
  created () {
    this.config = this.getComponentConfig(
      ARTICLE_HEADER_COMPONENT_KEY,
      ARTICLE_HEADER_DEFAULT_CONFIG
    )

    if (typeof this.contentSneakPeakPicker === 'undefined') {
      logger(
        '[ArticleHeader]: PARENT_CONTENT_DATA_KEY is [undefined]! Unable to resolve the component!',
        'warn'
      )
    }
  },
  mounted () {
    this.shareService = this.$container?.get<IShareService>(ShareServiceType)
  }
})
export class ArticleHeaderModuleUi extends Mixins<AbstractModuleUi<ArticleHeaderModule>, StructureConfigurable>(AbstractModuleUi, StructureConfigurable) {
  // @Inject(ShareServiceType)
  // public readonly shareService!: IShareService

  /**
   * Inject article content
   */
  @Inject({ from: PARENT_CONTENT_DATA_KEY })
  public readonly contentSneakPeakPicker!: ContentSneakPeakData

  public shareService?: IShareService

  /**
   * Get article lead
   */
  public get articleLead () {
    if (!this.contentSneakPeakPicker.properties) return
    return this.contentSneakPeakPicker.properties[AllowedAddon.Lead]
  }

  public get category (): AnyObject {
    const properties = this.contentSneakPeakPicker.properties
    return (properties[AllowedAddon.Categories] as AnyObject[])[0]
  }

  public get categoryUrl (): string {
    return `/${this.contentSneakPeakPicker.parent?.slug}/?category=${this.category.id}`
  }

  public get hasCategoryAddon (): boolean {
    const properties = this.contentSneakPeakPicker.properties
    return properties && (AllowedAddon.Categories in properties) && properties[AllowedAddon.Categories].length > 0
  }

  public get displayDate (): boolean {
    return this.getConfigProperty<boolean>('displayDate')
  }

  public get displayShare (): boolean {
    return this.getConfigProperty<boolean>('displayShare')
  }

  /**
   *
   */
  public get sizeMap () {
    return SizeMap
  }

  /**
   * Determines whether cover **IMAGE** is present.
   */
  public get hasCoverPhoto (): boolean {
    if (!this.contentSneakPeakPicker.properties) {
      return false
    }

    const coverAddon = this.contentSneakPeakPicker.properties[AllowedAddon.Cover]

    return !!coverAddon && Object.keys(coverAddon).includes('image')
  }

  public get coverPhoto (): ImageProps {
    if (!this.hasCoverPhoto) {
      return { src: '', alt: '' }
    }

    const image: ImageData = (this.contentSneakPeakPicker.properties[AllowedAddon.Cover] as AnyObject).image

    return toImageProps(image)
  }

  public get shareUrl () {
    if (typeof document === 'undefined') {
      return this.content.urlPath
    }

    if (!this.content.urlPath) {
      return document.location.origin + document.location.pathname
    }

    if (this.content.urlPath.indexOf('http') >= 0) {
      return this.content.urlPath
    }

    return document.location.origin + '/' + this.content.urlPath
  }

  public share (social: ShareType): void {
    if (!this.shareService) {
      return
    }

    this.shareService.share(social, {
      url: this.shareUrl,
      meta: {
        title: 'TODO: przeczytaj artykuł z aelia.pl',
        description: 'TODO: tresc maila o artykule ' + this.contentSneakPeakPicker.title
      }
    })
  }

  public shareEmail () {
    this.share(ShareType.Email)
  }

  public shareFb () {
    this.share(ShareType.Facebook)
  }
}

export default ArticleHeaderModuleUi
